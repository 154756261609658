import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/userInformation',
    name: 'userInformation',
    component: () => import('../views/userInformation/userInformation.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/chat/chat.vue')
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: () => import('../views/ticket/ticket.vue')
  },
  {
    path: '/ticket/create',
    name: 'ticketCreate',
    component: () => import('../views/ticket/ticketCreate.vue')
  },
  {
    path: '/paper',
    name: 'paper',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ai/paperReduction.vue')
  },
  {
    path: '/resourceView',
    name: 'resourceView',
    component: () => import('../views/function/resourceView.vue')
  },
  {
    path: '/term',
    name: 'term',
    component: () => import('../views/login/terms.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
