import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: null,
    routes:[],
  },
  getters: {
  },
  mutations: {
    setUserId(state, id) {
      state.userId = id;
    },
    initRoutes(state,data){
      state.routes = data
    }
  },
  actions: {
    updateUserId({ commit }, id) {
      commit('setUserId', id);
    },
  },
  modules: {
  }
})
