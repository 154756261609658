import axios from "axios";
import {Message} from "element-ui";
import router from "@/router/index";
let base = '/api';
const request = axios.create({
    baseURL: `${base}`,
    timeout: 60000
})



//请求拦截器
axios.interceptors.request.use(
    config=>{
        if (window.sessionStorage.getItem('tokenStr')){
            config.headers['Authorization'] = window.sessionStorage.getItem('tokenStr')
        }
        return config;
    },
    error =>{
        console.log(error)
    }
)
//请求拦截器
request.interceptors.request.use(
    config=>{
        if (window.sessionStorage.getItem('tokenStr')){
            config.headers['Authorization'] = window.sessionStorage.getItem('tokenStr')
        }
        return config;
    },
    error =>{
        console.log(error)
    }
)
// 响应拦截器
request.interceptors.response.use(
    (response) => {
        if (response.data.code===403){
            Message.error({message:"权限不足，请联系管理员"})
            router.replace('/login')
        }
        else if (response.data.code===401){
            Message.error({message:"尚未登录，请登录"})
            router.replace('/login')}
        else if (response && response.data){
            return response.data;}
        return response.data
        // return Promise.reject(new Error('No data found'));
    },
    (error) => {
        return Promise.reject(error);
    }
);


export  const  postRequest = (url,params) =>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data:params
    })
};
export  const  getRequest = (url,params) =>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data:params
    })
};

export default request;




