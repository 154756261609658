import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {getRequest} from "@/utils/request";
import 'highlight.js/styles/default.css';
import SlideVerify from 'vue-monoplasty-slide-verify';
import './style/index.scss'

Vue.use(SlideVerify);

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


router.beforeEach((to, from, next) => {
  if (window.sessionStorage.getItem("tokenStr")){
    // initMenu(router,store);
    if (!window.sessionStorage.getItem("user")){
      return getRequest('/admin/info').then(resp=>{
        if (resp){
          window.sessionStorage.setItem('user',JSON.stringify(resp));
          next();
        }
      })
    }

    next();
  }else {
    if (to.path === '/'){
      next()
    }else {
      next()
    }

  }
})